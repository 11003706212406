import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from '@opoint/infomedia-storybook'
import { Trans } from 'react-i18next'
import { DOWNTIME_EXPIRATION_TIMESTAMP } from '../../../constants/localStorage'
import { useLocalStorage } from '../../hooks/useLocalStorage'

const PLANNED_DOWNTIME = 1736519400 // 10.1.2025 15:30 (GMT+01:00)

const PlannedDowntimeDialog = () => {
  const [downtimeDialogExpiration, setDowntimeDialogExpiration] = useLocalStorage<number | null>(
    DOWNTIME_EXPIRATION_TIMESTAMP,
    null,
  )

  const isPastDowntime = Date.now() / 1000 > PLANNED_DOWNTIME

  // Determine if the downtime dialog should be open:
  // - It remains closed if the current time is past the planned downtime.
  // - Otherwise, it opens if there's no expiration set or if the dialog's expiration
  //   is earlier than the planned downtime.
  const open = isPastDowntime ? false : !downtimeDialogExpiration || downtimeDialogExpiration < PLANNED_DOWNTIME

  return (
    <AlertDialog
      open={open}
      onOpenChange={() => {
        setDowntimeDialogExpiration(PLANNED_DOWNTIME)
      }}
    >
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>
            <Trans>Planned downtime Friday, 10 January</Trans>
          </AlertDialogTitle>
          <AlertDialogDescription>
            <Trans>
              The portal will be <b>unavailable from 15:00 to 15:30 on Friday, 10 January</b>, due to a planned upgrade
              to a new database with higher capacity, which will improve system performance. We apologize for any
              inconvenience this may cause and thank you for your understanding.
            </Trans>
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogAction>
            <Trans>Got it</Trans>
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  )
}

export default PlannedDowntimeDialog
