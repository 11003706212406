import {
  Button,
  ButtonProps,
  cn,
  DropdownMenuItem,
  Tooltip,
  TooltipContent,
  TooltipPortal,
  TooltipTrigger,
} from '@opoint/infomedia-storybook'
import { ComponentRef, forwardRef, HTMLAttributes, ReactElement } from 'react'
import { FluentIcon } from '@fluentui/react-icons'
import { ActionVariant } from './types.ts'

type ActionBarProps = {
  id?: string
} & HTMLAttributes<HTMLDivElement>

const ActionBar = ({ id, children, className }: ActionBarProps) => (
  <div className={cn('border-b border-grey.6', className)}>
    <div id={id} className="flex h-xxl justify-between bg-grey.8 p-2.5">
      {children}
    </div>
  </div>
)

type ActionBarLeftProps = HTMLAttributes<HTMLDivElement>

export const ActionBarLeft = ({ children, className, ...props }: ActionBarLeftProps) => (
  <div className={cn('z-10 flex h-11', className)} {...props}>
    {children}
  </div>
)

type ActionBarActionProps = {
  actionVariant: ActionVariant
  label: string
  tooltip?: string
  icon: FluentIcon
} & Omit<ButtonProps, 'size' | 'loading' | 'children'>

export const ActionBarAction = forwardRef<ComponentRef<typeof Button>, ActionBarActionProps>(
  ({ actionVariant, label, tooltip, variant = 'outline', disabled = false, icon: Icon, ...props }, ref) => {
    let trigger: ReactElement | null = null

    switch (actionVariant) {
      case ActionVariant.SHORT: {
        trigger = (
          <Button ref={ref} variant={variant} size="icon" disabled={disabled} {...props}>
            <Icon />
            <span className="sr-only">{label}</span>
          </Button>
        )
        break
      }

      case ActionVariant.LONG: {
        trigger = (
          <Button ref={ref} variant={variant} disabled={disabled} {...props}>
            <Icon aria-hidden />
            {label}
          </Button>
        )
        break
      }

      case ActionVariant.MENU: {
        trigger = (
          <DropdownMenuItem
            disabled={disabled}
            onSelect={(e) => e.preventDefault()}
            onPointerMove={(e) => {
              e.preventDefault()
            }}
            onPointerLeave={(e) => {
              e.preventDefault()
            }}
            asChild
          >
            <button ref={ref} {...props}>
              <Icon aria-hidden />
              {label}
            </button>
          </DropdownMenuItem>
        )
        break
      }

      default: {
        return null
      }
    }

    // Render a tooltip if the action is disabled and has a tooltip text,
    // or if the button uses the SHORT variant (which always displays the action label as a tooltip).
    if ((disabled && tooltip) || actionVariant === ActionVariant.SHORT) {
      return (
        <Tooltip>
          <TooltipTrigger asChild>
            <div>{trigger}</div>
          </TooltipTrigger>
          <TooltipPortal>
            <TooltipContent>{tooltip ?? label}</TooltipContent>
          </TooltipPortal>
        </Tooltip>
      )
    }

    return trigger
  },
)
ActionBarAction.displayName = 'ActionBarAction'

export default ActionBar
